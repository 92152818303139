import { Directive, ElementRef, Renderer2, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';

@Directive({ selector: '[appCountryFlag]' })
export class CountryFlagDirective implements OnInit, OnChanges {
  @Input() appCountryFlag: string;

  constructor(private element: ElementRef, private renderer: Renderer2) {}

  ngOnInit() {
    this.renderer.addClass(this.element.nativeElement, 'flag-icon');
    this.renderer.addClass(this.element.nativeElement, 'mat-flag-icon');
    this.renderer.addClass(this.element.nativeElement, 'flag-icon-square');
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.appCountryFlag) {
      if (!changes.appCountryFlag.firstChange) {
        this.renderer.removeClass(
          this.element.nativeElement,
          `flag-icon-${changes.appCountryFlag.previousValue.toLocaleLowerCase()}`,
        );
      }
      this.renderer.addClass(
        this.element.nativeElement,
        `flag-icon-${changes.appCountryFlag.currentValue.toLocaleLowerCase()}`,
      );
    }
  }
}
