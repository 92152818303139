import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import jssha from 'jssha';
import { environment } from '../../../../environments/environment';
import { Team } from '../../../model/team';
import { NotificationDlg } from '../../../services';
import { AppUser } from '../../../services/core/user.model';
import { TeamsService } from '../../../services/teams/teams.service';
import regions from '../add-team/regions';

@Component({
  selector: 'app-edit-team',
  templateUrl: './edit-team.component.html',
  styleUrls: ['./edit-team.component.scss'],
})
export class EditTeamComponent implements OnInit {
  team: Team;
  teamForm: FormGroup;
  userForm: FormGroup;
  newUsers: FormArray;
  saving: boolean;
  activeUser: AppUser;
  userCreateMode: boolean;
  teams: Team[] = [];
  users: AppUser[] = [];
  rows = this.users;
  limit = 20;
  offset = 0;
  totalCount = 6;
  private teamId: string;

  constructor(
    private teamsService: TeamsService,
    private route: ActivatedRoute,
    private teamService: TeamsService,
    private notificationService: NotificationDlg,
    private fb: FormBuilder,
  ) {
    const routeSnapshot = route.snapshot;
    this.teamId = routeSnapshot.paramMap.get('id');
  }

  ngOnInit() {
    this.teamForm = this.fb.group({
      id: ['', [Validators.required]],
      name: ['', [Validators.required]],
      type: ['', [Validators.required]],
      accountType: [{ value: 'trial' }, [Validators.required]],
    });

    this.userForm = new FormGroup({
      displayName: new FormControl('', [Validators.required]),
      role: new FormControl('', [Validators.required]),
    });

    this.newUsers = this.fb.array([this.buildUserInvite()]);
    this.loadTeam();
  }

  private loadUsers() {
    this.teamService.getTeamUsers(this.teamId).subscribe((users) => {
      users.forEach((x) => {
        x.hash = this.teamService.getHash(x.email);
        x.inspectletUrl = this.getInspectletUrl(this.team.analyticsId, x.analyticsId);
      });
      this.users = users;
    });
  }

  private loadTeam() {
    this.teamService.getTeamById(this.teamId).subscribe((team) => {
      this.team = team;
      this.setDefaultsOnTeam();
      this.teamForm.patchValue(this.team);
      this.loadUsers();
    });
  }

  private setDefaultsOnTeam() {
    if (this.team.type) return;
    this.team.type = 'customer';
  }

  private buildUserInvite(): FormGroup {
    return this.fb.group({
      displayName: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      role: ['member', [Validators.required]],
    });
  }

  private computeHash(id: string): string {
    const shaObj = new jssha('SHA-256', 'TEXT');
    shaObj.update(id);
    return shaObj.getHash('HEX');
  }

  onGetJursidictionName(countryCode): string {
    const country = regions.find((x) => x.code.toLowerCase() === countryCode);
    return country.name;
  }

  onPageChange(page: any): void {
    this.offset = page;
  }

  onPageSizeChanged(pageSize) {
    this.limit = pageSize;
    this.onChangeLimit();
  }

  onChangeLimit(): void {
    this.offset = 0;
  }

  onToggleUserCreate() {
    if (this.activeUser) {
      this.activeUser = null;
      return;
    }
    this.userCreateMode = !this.userCreateMode;
  }

  onUserRemove(index) {
    this.newUsers.removeAt(index);
  }

  onResetPassword(user) {
    if (confirm(`Are you sure you want to send the reset password email to ${user.email}`)) {
      user.resettingPassword = true;
      this.teamService.sendResetPasswordLink(this.teamId, user.id).subscribe({
        next: () => {
          this.notificationService.openDialog({
            text: 'Password Reset Email sent successfully',
            type: 'success',
          });
        },
        complete: () => (user.resettingPassword = false),
      });
    }
  }

  onSendInvitation(user: any) {
    if (confirm(`Are you sure you want to send the invitation email to ${user.email}`)) {
      user.sendingInvite = true;
      this.teamService.sendInvitation(this.teamId, user.id).subscribe({
        next: () => {
          this.notificationService.openDialog({
            text: 'Invitation Email sent successfully',
            type: 'success',
          });
        },
        complete: () => (user.sendingInvite = false),
      });
    }
  }

  onRemoveUser(user) {
    if (confirm(`Are you sure you want to remove ${user.displayName}`)) {
      this.teamService.removeUser(this.teamId, user.id).subscribe(() => {
        this.loadUsers();
      });
    }
  }

  onUserAdd() {
    this.newUsers.push(this.buildUserInvite());
  }

  onUsersCreate() {
    this.teamService.inviteUsers(this.teamId, this.newUsers.value).subscribe(() => {
      this.newUsers = this.fb.array([this.buildUserInvite()]);
      this.onToggleUserCreate();
      this.loadUsers();
    });
  }

  onUserUpdate() {
    const payload = {
      displayName: this.userForm.controls.displayName.value,
      role: this.userForm.controls.role.value,
    };
    this.teamService.updateUser(this.teamId, this.activeUser.id, payload).subscribe((user: AppUser) => {
      this.loadUsers();
      this.activeUser = null;
      this.userForm.reset();
    });
  }

  saveTeam() {
    this.saving = true;
    if (this.teamForm.valid) {
      this.teamsService.updateTeam(this.teamForm.value).subscribe(
        (team) => {
          team.teamHash = this.computeHash(team.tenantId);
          this.team = team;
          this.notificationService.openDialog({
            text: 'Tenant was updated successfully',
            type: 'success',
          });
        },
        (err) => {
          this.notificationService.openDialog({
            text: err,
            type: 'error',
          });
        },
        () => {
          this.saving = false;
        },
      );
    }
  }

  onEditUser(user: AppUser) {
    this.activeUser = user;
    this.userForm.controls.displayName.setValue(this.activeUser.displayName);
    this.userForm.controls.role.setValue(this.activeUser.role);
  }

  private getInspectletUrl(teamId: string, userId: string) {
    const inspectletParams = {
      paneopen: 'basic',
      tagslist: [
        {
          tag: 'teamId',
          value: teamId,
        },
        {
          tag: 'userid',
          value: userId,
        },
      ],
      operator: 'and',
    };
    const inspectletUrl = new URL(environment.inspectletBaseUrl);
    inspectletUrl.searchParams.append('tags', JSON.stringify(inspectletParams));
    return inspectletUrl.href;
  }

  advanceToNextStage() {
    switch (this.team.accountType) {
      case 'free':
        this.teamsService.startTrial(this.teamId).subscribe((x) => {
          this.loadTeam();
        });
        break;
      case 'trial':
        this.teamsService.activate(this.teamId).subscribe((x) => {
          this.loadTeam();
        });
        break;
    }
  }

  getNextStageText() {
    switch (this.team.accountType) {
      case 'free':
        return 'Start Trial';
      case 'trial':
        return 'Activate';
    }
  }
}
