import {
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewEncapsulation,
} from '@angular/core';
import { Option } from '../../types/options';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TableComponent implements OnInit {
  @ContentChild('tableHeader') tableHeader: TemplateRef<any>;
  @ContentChild('tableRows') tableRows: TemplateRef<any>;
  @Input() header: any[] = [];
  @Input() rows: any[] = [];
  @Input() pageSize;
  @Input() totalCount: number;
  @Input() offset: number;
  @Input() currentPage;
  @Output() pageChanged = new EventEmitter();
  @Output() pageSizeChanged = new EventEmitter();
  availablePages: number[] = [];
  pages: number[] = [];
  maxPages: number;
  pageOptions: Option[] = [
    { name: '20', value: 20 },
    { name: '40', value: 40 },
    { name: '100', value: 100 },
  ];
  activePageOption: Option;

  ngOnInit() {
    if (!this.currentPage) {
      this.currentPage = 0;
    }
    if (!this.pageSize) {
      this.pageSize = 20;
    }
    this.selectActiveOption(this.pageSize);
    this.maxPages = Math.ceil(this.totalCount / this.pageSize);
    this.onUpdateAvailablePages();
  }

  onUpdateAvailablePages() {
    this.availablePages = [
      this.currentPage - 2,
      this.currentPage - 1,
      this.currentPage,
      this.currentPage + 1,
      this.currentPage + 2,
    ];
  }

  onPrevPage() {
    if (this.currentPage - 1 > 0) {
      this.currentPage -= 1;
    }
    this.onUpdateAvailablePages();
    this.onChange();
  }

  onNextPage() {
    if (this.currentPage + 1 < this.maxPages) {
      this.currentPage += 1;
    }
    this.onUpdateAvailablePages();
    this.onChange();
  }

  onSetPage(page: number) {
    if (page === this.currentPage) {
      return;
    }
    this.currentPage = page;
    this.onUpdateAvailablePages();
    this.onChange();
  }

  onChange() {
    this.pageChanged.emit(this.currentPage);
  }

  onPageSizeChanged(sizeOption: Option) {
    this.pageSize = sizeOption.value;
    this.pageSizeChanged.emit(this.pageSize);
  }

  selectActiveOption(value: number) {
    for (const option of this.pageOptions) {
      if (option.value === value) {
        this.activePageOption = option;
        return;
      }
    }
  }
}
