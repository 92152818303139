import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ListTeamsComponent } from './list-teams/list-teams.component';
import { AddTeamComponent } from './add-team/add-team.component';
import { AuthGuard } from '../../services/core/auth.guard';
import { EditTeamComponent } from './edit-team/edit-team.component';

const routes: Routes = [
  {
    path: '',
    component: ListTeamsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'addteam',
    component: AddTeamComponent,
    canActivate: [AuthGuard],
  },
  {
    path: ':id',
    component: EditTeamComponent,
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class TeamsRoutingModule {}
