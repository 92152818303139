import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Option } from '../../types/options';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
})
export class SelectComponent {
  @Input() dropdownFilter: boolean;
  @Input() name: string;
  @Input() placeholder: string;
  @Input() options: Option[];
  @Input() disabled: boolean;
  @Input() classList: string;
  @Input() selectedOption: Option;
  @Input() hideIcon: boolean;
  @Input() countryFilter: boolean;
  @Input() fullWidth: boolean;
  @Output() changed = new EventEmitter();
  dropdownOpen: boolean;

  updateToActive(obj) {
    for (const option of this.options) {
      option.selected = option === obj;
    }
  }

  selectOption(option) {
    this.updateToActive(option);
    this.selectedOption = option;
    this.onChange(option);
  }

  toggleDropdown($event) {
    this.dropdownOpen = !this.dropdownOpen;
  }

  onChange(option) {
    this.changed.emit(option);
  }
}
