export class Team {
  id: string;
  type: string;
  googleId: string;
  jurisdiction: string;
  businessId?: string;
  name: string;
  identifier: string;
  adminEmail: string;
  teamHash: string;
  analyticsId: string;
  inspectletUrl: string;
  adminDisplayName: string;
  tenantId: string;
  accountType?: string;
  creationDate?: string;
  creationDateString?: string;
  trialStartDate?: string;
  trialStartDateString?: string;
  activationDate?: string;
  activationDateString?: string;

  constructor() {
    this.type = 'customer';
  }
}

export interface TeamRecords {
  totalCount: number;
  skip: number;
  take: number;
  teams: Team[];
}
