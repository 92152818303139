import { Component, OnInit, ViewChild } from '@angular/core';
import { Communication, CommunicationEntity } from '../../model/communication';
import { TeamsService } from '../../services/teams/teams.service';
import { DomSanitizer } from '@angular/platform-browser';
import { FormControl } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { forkJoin, merge } from 'rxjs';
import { NotificationDlg } from '../../services';
import { PageEvent } from '@angular/material/paginator';
import moment from 'moment';

@Component({
  selector: 'contingent-communications',
  templateUrl: './communications.component.html',
  styleUrls: ['./communications.component.scss'],
})
export class CommunicationsComponent implements OnInit {
  @ViewChild('communicationsTable') table: any;
  entities: Communication[] = [];
  selectedEntities: Communication[] = [];
  expanded: any = {};
  loading = true;
  notifications: CommunicationEntity[] = [];
  notification: string;
  query: FormControl = new FormControl('');
  reviewStatus: boolean;
  tableOffset = 0;
  tableSkip = 0;
  tableTake = 100;
  tableTotal: number;
  team: string;
  teams: CommunicationEntity[] = [];
  selectedEntity: Communication;
  currentPage = 1;
  activeEntity: Communication;
  allSelected = false;
  sortBy: string;
  sortByDirection: string;

  constructor(
    private teamService: TeamsService,
    private domSanitizer: DomSanitizer,
    private notificationService: NotificationDlg,
  ) {}

  ngOnInit(): void {
    this.teamService.getReviewStatus().subscribe((data) => {
      this.reviewStatus = data.Value;
    });
    this.getCommunications();
    this.query.valueChanges.pipe(debounceTime(400)).subscribe((data) => {
      this.getCommunications();
    });
  }

  onPageChange(e) {
    this.tableSkip = e * this.tableTake;
    this.tableOffset = e;
    this.getCommunications();
  }

  onChangeLimit(e) {
    this.tableTake = e;
    this.getCommunications();
  }

  updateReviewStatus(status: MatCheckboxChange) {
    const checked = status.checked;
    this.teamService.updateReviewStatus(checked).subscribe((data) => {
      this.reviewStatus = data.Value;
    });
  }

  applyFilter(value, type) {
    switch (type) {
      case 'TEAM':
        this.team = value;
        break;
      case 'NOTIFICATION_TYPE':
        this.notification = value;
        break;
    }
    this.resetTablePaging();
    this.getCommunications();
  }

  resetTablePaging() {
    this.tableTake = 10;
    this.tableSkip = 0;
  }

  discard(e, item: Communication) {
    e.preventDefault();
    e.stopPropagation();
    this.teamService.discardEmail(item.id).subscribe(
      () => {
        const data = {
          text: 'Email has been discarded.',
          type: 'success',
        };
        this.notificationService.openDialog(data);
      },
      (error) => {
        const data = {
          text: 'Failed to discard email.',
          type: 'error',
        };
        this.notificationService.openDialog(data);
      },
      () => this.getCommunications(),
    );
  }

  release(e, item: Communication) {
    e.preventDefault();
    e.stopPropagation();
    this.teamService.releaseEmail(item.id).subscribe(
      () => {
        const data = {
          text: 'Email has been released.',
          type: 'success',
        };
        this.notificationService.openDialog(data);
      },
      (error) => {
        const data = {
          text: 'Failed to release email.',
          type: 'error',
        };
        this.notificationService.openDialog(data);
      },
      () => this.getCommunications(),
    );
  }

  getCommunications() {
    this.loading = true;
    const queryParams: any = {
      skip: this.tableSkip,
      take: this.tableTake,
      filter: [],
    };

    if (this.query) {
      queryParams.query = this.query.value;
    }

    if (this.sortBy) {
      queryParams.name = this.sortBy;
    }

    if (this.sortByDirection) {
      queryParams.direction = this.sortByDirection;
    }

    const filters = [];
    if (this.team && this.team.length) {
      filters.push(`tenantId=${this.team}`);
    }
    if (this.notification && this.notification.length) {
      filters.push(`notificationType=${this.notification}`);
    }

    queryParams.filter = filters;

    this.teamService.getAllMessage(queryParams).subscribe((data) => {
      this.selectedEntities = [];
      this.entities = data.results;
      this.entities.forEach((x) => {
        x.preview = this.domSanitizer.bypassSecurityTrustHtml(x.preview) as string;

        const timestamp = moment(x.timestamp);
        x.timestamp = `${timestamp.format('Do MMMM YYYY')} (${timestamp.fromNow()})`;
      });
      this.tableTake = parseInt(data.paging.take, 10);
      this.tableSkip = parseInt(data.paging.skip, 10);
      this.tableTotal = parseInt(data.paging.totalResults, 10);

      if (this.entities.length) {
        this.activeEntity = this.entities[0];
      }

      this.loading = false;
    });

    this.teamService.getAllMessage({ query: this.query.value }).subscribe((data) => {
      this.notifications = data.facets.notificationTypes;
      this.teams = data.facets.teams;
    });
  }

  selectCommunication(communication: Communication) {
    this.activeEntity = communication;
  }

  selectItem(event: MatCheckboxChange, row: Communication) {
    row.selected = event.checked;
    if (row.selected) {
      this.selectCommunication(row);
    }
    this.selectedEntities = this.entities.filter((e) => e.selected);
  }

  selectAllItems(event: MatCheckboxChange) {
    this.entities.forEach((e) => {
      e.selected = event.checked;
    });
    this.selectedEntities = this.entities.filter((e) => e.selected);
  }

  releaseSelected() {
    forkJoin(this.entities.filter((e) => e.selected).map((e) => this.teamService.releaseEmail(e.id))).subscribe({
      next: () => {
        const data = {
          text: 'The selected emails have been released.',
          type: 'success',
        };
        this.notificationService.openDialog(data);
      },
      error: (error) => {
        const data = {
          text: 'Failed to release some or all of the selected emails.',
          type: 'error',
        };
        this.notificationService.openDialog(data);
      },
      complete: () => this.getCommunications(),
    });
  }

  discardSelected() {
    forkJoin(this.entities.filter((e) => e.selected).map((e) => this.teamService.discardEmail(e.id))).subscribe({
      next: () => {
        const data = {
          text: 'The selected emails have been discarded.',
          type: 'success',
        };
        this.notificationService.openDialog(data);
      },
      error: (error) => {
        const data = {
          text: 'Failed to discard some or all of the selected emails.',
          type: 'error',
        };
        this.notificationService.openDialog(data);
      },
      complete: () => this.getCommunications(),
    });
  }

  changePage($event: PageEvent) {
    this.tableSkip = $event.pageSize * $event.pageIndex;
    this.tableTake = $event.pageSize;
    this.allSelected = false;
    this.getCommunications();
  }

  showActionButtons(row: Communication) {
    row.mouseOver = true;
  }

  hideActionButtons(row: Communication) {
    row.mouseOver = false;
  }

  sort(field: string) {
    this.sortBy = field;
    this.getCommunications();
  }

  sortDirection(direction: string) {
    this.sortByDirection = direction;
    this.getCommunications();
  }
}
