import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomValidators } from 'ngx-custom-validators';
import { Team } from '../../../model/team';
import { NotificationDlg } from '../../../services';
import { TeamsDataSource } from '../../../services/teams/teams.datasource';
import { TeamsService } from '../../../services/teams/teams.service';
import regions from './regions';

@Component({
  selector: 'app-add-team',
  templateUrl: './add-team.component.html',
  styleUrls: ['./add-team.component.css'],
})
export class AddTeamComponent implements OnInit {
  @Output() emitter: EventEmitter<any>;
  addEdit = '';
  dataSource: TeamsDataSource;
  editId: string;
  isEdit: boolean;
  maxId: number;
  regions = regions;
  team: Team;
  teamForm: FormGroup;
  teams: Team[];
  selectedTeamType: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private fromBuilder: FormBuilder,
    private teamsService: TeamsService,
    private notificationService: NotificationDlg,
  ) {
    this.emitter = new EventEmitter();
    this.buildRuleForm();
    this.team = new Team();
    this.maxId = 0;
  }

  ngOnInit() {
    this.editId = this.route.snapshot.params.id;
    this.addEdit = this.editId ? 'Update' : 'Create';
    this.isEdit = !!this.editId;
    if (this.isEdit) {
      this.getTeam();
    }
  }

  filterValues(filterValue: string) {
    this.regions = regions.filter((value) => value.name.toLowerCase().indexOf(filterValue.toLowerCase()) === 0);
  }

  getTeam() {
    this.teamsService.getTeamById(this.editId).subscribe(
      (data) => {
        this.team = data;
        const index = this.regions.findIndex((el) => el.code.toLowerCase() === this.team.jurisdiction.toLowerCase());
        this.setDefaultsForTeam(index);
      },
      (err) => {
        console.log(err.message);
      },
    );
  }

  private setDefaultsForTeam(index: number) {
    this.team.jurisdiction = this.regions[index].name;
    if (this.team.type) return;

    this.team.type = 'customer';
  }

  backtoList() {
    this.router.navigateByUrl('/teams');
  }

  saveTeam() {
    const index = this.regions.findIndex((el) => el.name === this.team.jurisdiction);
    const teamSave = { ...this.team };
    teamSave.jurisdiction = this.regions[index].code.toLowerCase();

    if (this.isEdit) {
      this.teamsService.updateTeam(teamSave).subscribe(
        (res) => {
          this.notificationService.openDialog({
            text: 'Tenant was updated successfully',
            type: 'success',
          });
          this.backtoList();
        },
        (err) => {
          console.log(err);
        },
      );
    } else {
      this.teamsService.saveTeam(teamSave).subscribe(
        (res) => {
          this.notificationService.openDialog({
            text: 'Tenant was created successfully. Sent the reset password link via email to ' + this.team.adminEmail,
            type: 'success',
          });
          this.backtoList();
        },
        (err) => {
          console.log(err);
        },
      );
    }
  }

  jurisdictionValidator(control: AbstractControl) {
    if (regions.findIndex((el) => el.name === control.value) === -1) {
      return { jurisdictionValid: true };
    }
    return null;
  }

  buildRuleForm() {
    this.teamForm = this.fromBuilder.group({
      name: ['', [Validators.required, Validators.minLength(4)]],
      jurisdiction: ['', [Validators.required, this.jurisdictionValidator]],
      businessId: [
        '',
        [Validators.pattern('^^([0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12})?$')],
      ],
      identifier: [
        '',
        [
          Validators.required,
          Validators.minLength(4),
          Validators.maxLength(20),
          Validators.pattern('^^[A-Za-z][A-Za-z0-9-]+$'),
        ],
      ],
      email: ['', [Validators.required, CustomValidators.email]],
      adminDisplayName: ['', [Validators.required]],
      type: ['customer', [Validators.required]],
    });
  }
}
