import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../services/core/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  form: FormGroup;
  private readonly returnUrl: string;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
  ) {
    this.returnUrl = this.route.snapshot.queryParams.returnUrl || '/';
  }

  ngOnInit() {
    if (this.authService.isAuthenticated()) {
      this.router.navigate([this.returnUrl]);
    }
  }

  async tryGoogleLogin() {
    await this.authService
      .logIn()
      .then((res) => {
        console.log(res);
        return res;
      })
      .catch((err) => {
        console.log(err);
      });
    if (this.authService.isAuthenticated()) {
      this.router.navigate([this.returnUrl]);
    }
  }
}
