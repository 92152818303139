import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminLayoutComponent, AuthLayoutComponent } from './core';
import { AuthGuard } from './services/core/auth.guard';

const routes: Routes = [
  {
    path: '',
    component: AdminLayoutComponent,
    children: [
      {
        path: '',
        redirectTo: '/teams',
        pathMatch: 'full',
      },
      {
        path: 'teams',
        canActivate: [AuthGuard],
        loadChildren: () => import('./components/teams/teams.module').then((m) => m.TeamsModule),
      },
      {
        path: 'communications',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./components/communications/communications.module').then((m) => m.CommunicationsModule),
      },
    ],
  },
  {
    path: '',
    component: AuthLayoutComponent,
    children: [
      {
        path: 'session',
        loadChildren: () => import('./components/session/session.module').then((m) => m.SessionModule),
      },
    ],
  },
  {
    path: '**',
    redirectTo: 'session/404',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
