import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { Team, TeamRecords } from '../../model/team';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { TeamsService } from './teams.service';
import { catchError, finalize } from 'rxjs/operators';

export class TeamsDataSource implements DataSource<Team> {
  private teamsSubject = new BehaviorSubject<Team[]>([]);
  teams: Team[];
  private loadingSubject = new BehaviorSubject<boolean>(false);

  loading$ = this.loadingSubject.asObservable();

  constructor(private teamsService: TeamsService) {}

  loadTeams(
    filter: string,
    sortBy: string,
    sortDirection: string,
    pageIndex: number,
    pageSize: number,
  ): Observable<TeamRecords> {
    this.loadingSubject.next(true);

    return this.teamsService.getAllTeamsByServer(filter, sortBy, sortDirection, pageIndex, pageSize);
  }

  saveTeam(team: Team): Observable<Team> {
    this.loadingSubject.next(true);
    return this.teamsService.saveTeam(team);
  }

  updateTeam(team: Team): Observable<Team> {
    this.loadingSubject.next(true);
    return this.teamsService.updateTeam(team);
  }

  deleteTeam(team: Team) {
    this.loadingSubject.next(true);
    return this.teamsService.deleteTeam(team);
  }

  connect(collectionViewer: CollectionViewer): Observable<Team[]> {
    return this.teamsSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.teamsSubject.complete();
    this.loadingSubject.complete();
  }
}
