import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { map, catchError, switchMap } from 'rxjs/operators';
import { UserService } from '../core/user.service';
import { NotificationDlg } from '../notificationDlg/notificationDlg.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from '../core/auth.service';

@Injectable()
export class ResearchWebBearerTokenInterceptor implements HttpInterceptor {
  constructor(
    public notificationService: NotificationDlg,
    private userService: UserService,
    private auth: AuthService,
    public snackBar: MatSnackBar,
  ) {}

  generateHandle(request, next): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        const data = {
          text: error.error ? error.error.errorMessage : 'Unknown Error',
          type: 'error',
        };
        this.notificationService.openDialog(data);
        return throwError(error);
      }),
    );
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token: string = this.userService.getCurrentUser() ? this.userService.getCurrentUser().idToken : null;

    if (token) {
      request = request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + token) });
    }

    if (!request.headers.has('Content-Type')) {
      request = request.clone({ headers: request.headers.set('Content-Type', 'application/json') });
    }

    request = request.clone({ headers: request.headers.set('Accept', 'application/json') });

    if (!this.auth.isAuthenticated()) {
      return this.auth.getIdToken().pipe(
        switchMap((res: any) => {
          if (res !== false) {
            request = request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + res) });
          }
          return this.generateHandle(request, next);
        }),
      );
    }
    return this.generateHandle(request, next);
  }
}
