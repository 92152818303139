import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Team, TeamRecords } from '../../model/team';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { AdminUser, AppUser, User } from '../core/user.model';
import jssha from 'jssha';
import { Communication, CommunicationEntity } from '../../model/communication';

interface CommunicationPayload {
  results: Communication[];
  paging: {
    skip: string;
    take: string;
    totalResults: string;
  };
  facets: {
    notificationTypes: {
      id: string;
      name: string;
      count: number;
    }[];
    teams: {
      id: string;
      name: string;
      count: number;
    }[];
  };
}

@Injectable()
export class TeamsService {
  teams: Team[];
  headers: any;
  stringTeams: string[];
  constructor(private http: HttpClient) {}

  getHash(teamId: string) {
    const shaObj = new jssha('SHA-256', 'TEXT');
    shaObj.update(teamId);
    return shaObj.getHash('HEX');
  }

  getAllTeams(): Observable<TeamRecords> {
    return this.http.get(`${environment.apiUri}/${environment.teamEndpoint}`) as Observable<TeamRecords>;
  }

  getTeamById(tId): Observable<Team> {
    return this.http.get(environment.apiUri + '/teams/' + tId) as Observable<Team>;
  }

  getTeamUsers(tId): Observable<AppUser[]> {
    return this.http.get(environment.apiUri + `/teams/${tId}/users/`) as Observable<AppUser[]>;
  }

  getAllTeamsByServer(
    filter: string,
    sortBy: string,
    sortDirection: string,
    pageIndex: number,
    pageSize: number,
  ): Observable<TeamRecords> {
    // eslint-disable-next-line max-len
    return this.http.get(
      environment.apiUri +
        '/teams?filter=' +
        filter +
        '&sortBy=' +
        sortBy +
        '&sortDirection=' +
        sortDirection +
        '&pageIndex=' +
        pageIndex +
        '&pageSize=' +
        pageSize +
        '',
    ) as Observable<TeamRecords>;
  }

  saveTeam(team: Team): Observable<Team> {
    return this.http.post(environment.apiUri + '/teams', team) as Observable<Team>;
  }

  updateTeam(team: Team): Observable<Team> {
    return this.http.put(environment.apiUri + '/teams/' + team.id, team) as Observable<Team>;
  }

  deleteTeam(team: Team) {
    return this.http.delete(environment.apiUri + '/teams/' + team.id);
  }

  inviteUsers(teamId: string, users: AdminUser[]) {
    return this.http.post(`${environment.apiUri}/teams/${teamId}/users`, users);
  }

  updateUser(teamId: string, userId: string, payload): Observable<AppUser> {
    return this.http.put<AppUser>(`${environment.apiUri}/teams/${teamId}/users/${userId}`, payload);
  }

  removeUser(teamId: string, userId: string) {
    return this.http.delete(`${environment.apiUri}/teams/${teamId}/users/${userId}`);
  }

  sendResetPasswordLink(teamId: string, userId: string) {
    return this.http.post(`${environment.apiUri}/teams/${teamId}/users/${userId}/send-reset-password`, null);
  }

  sendInvitation(teamId: string, userId: string) {
    return this.http.post(`${environment.apiUri}/teams/${teamId}/users/${userId}/send-invite`, null);
  }

  startTrial(teamId: string) {
    return this.http.post(`${environment.apiUri}/teams/${teamId}/start-trial`, null);
  }

  activate(teamId: string) {
    return this.http.post(`${environment.apiUri}/teams/${teamId}/activate`, null);
  }

  getAllMessage(params): Observable<CommunicationPayload> {
    return this.http.get<CommunicationPayload>(`${environment.apiUri}/communications/emails`, { params });
  }

  discardEmail(id: string): Observable<any> {
    return this.http.delete<any>(`${environment.apiUri}/communications/emails/${id}`);
  }

  releaseEmail(id: string): Observable<any> {
    return this.http.post<any>(`${environment.apiUri}/communications/emails/${id}/release`, null);
  }

  updateReviewStatus(status: boolean): any {
    return this.http.put(`${environment.apiUri}/settings/IsWeeklyDigestEmailReviewRequired`, { value: status });
  }

  getReviewStatus(): any {
    return this.http.get(`${environment.apiUri}/settings/IsWeeklyDigestEmailReviewRequired`);
  }
}
