import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NotificationMessageComponent } from './notificationmessagecomponent/notificationmessagecomponent';

// TODO: Make this better; will do for now
@Injectable()
export class NotificationDlg {
  constructor(public snackBar: MatSnackBar) {}
  openDialog(data): void {
    this.snackBar.openFromComponent(NotificationMessageComponent, {
      data,
      duration: 5000,
      verticalPosition: 'top',
      panelClass: 'snack-notification-' + data.type,
    });
  }
}
