import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import moment from 'moment';
import { environment } from '../../../../environments/environment';
import { IPagingContext, ISortContext } from '../../../model/entities';
import { Team } from '../../../model/team';
import { NotificationDlg } from '../../../services';
import { TeamsDataSource } from '../../../services/teams/teams.datasource';
import { TeamsService } from '../../../services/teams/teams.service';

@Component({
  selector: 'app-list-teams',
  templateUrl: './list-teams.component.html',
  styleUrls: ['./list-teams.component.scss'],
})
export class ListTeamsComponent implements AfterViewInit {
  constructor(private router: Router, private teamsService: TeamsService, public notificationService: NotificationDlg) {
    this.dataSource = new TeamsDataSource(teamsService);
    this.getTeams();
  }
  @ViewChild('teamTable') table: DatatableComponent;
  teams: Team[] = [];
  dataSource: TeamsDataSource;
  pagingContext: IPagingContext = { skip: 0, totalResults: 0, take: 20 };
  sortContext: ISortContext = { name: 'identifier', direction: 'asc' };
  requestingData = true;
  filtering = false;
  limit = 10;
  offset = 0;
  filterText = '';

  private static creationDate(team: Team) {
    return team.creationDate ? moment(team.creationDate).fromNow() : '';
  }

  private static trialStartDate(team: Team) {
    if (team.accountType === 'trial' || team.accountType === 'paid') {
      return team.trialStartDate ? moment(team.trialStartDate).fromNow() : '';
    }

    return '';
  }

  private static activationDate(team: Team) {
    if (team.accountType === 'paid') {
      return team.activationDate ? moment(team.activationDate).fromNow() : '';
    }

    return '';
  }

  ngAfterViewInit() {
    this.table.onColumnSort = (event) => {
      this.sortContext.name = event.column.prop;
      this.sortContext.direction = event.newValue;
      this.offset = 0;
      this.filtering = true;
      this.getTeams();
    };
  }

  getTeams() {
    this.dataSource
      .loadTeams(this.filterText, this.sortContext.name, this.sortContext.direction, this.offset + 1, this.limit)
      .subscribe(
        (data) => {
          this.teams = data.teams;
          this.teams.forEach((t) => {
            t.teamHash = this.teamsService.getHash(t.tenantId);
            t.inspectletUrl = this.getInspectletUrl(t.analyticsId);
            t.creationDateString = ListTeamsComponent.creationDate(t);
            t.trialStartDateString = ListTeamsComponent.trialStartDate(t);
            t.activationDateString = ListTeamsComponent.activationDate(t);
          });
          this.pagingContext = {
            skip: data.skip,
            totalResults: data.totalCount,
            take: data.take,
          };
          this.filtering = false;
          this.requestingData = false;
        },
        (err: HttpErrorResponse) => {
          console.log(err.message);
          this.filtering = false;
          this.requestingData = false;
        },
      );
  }

  addteam() {
    this.router.navigateByUrl('/teams/addteam');
  }

  editteam(team: Team = null) {
    this.router.navigate([`/teams/${team.id}`]);
  }
  deleteteam(team: Team = null) {
    if (confirm('Are you sure to delete : ' + team.name)) {
      this.dataSource.deleteTeam(team).subscribe(
        (data) => {
          this.getTeams();
          this.notificationService.openDialog({
            text: 'Tenant was deleted successfully',
            type: 'success',
          });
        },
        (err) => {
          console.log(err.message);
        },
      );
    }
  }

  applyFilter(filter: string) {
    filter = filter.trim();
    filter = filter.toLowerCase();
    this.filterText = filter;
    this.filtering = true;
    this.offset = 0;
    this.getTeams();
  }
  onPageChange(event) {
    this.offset = event.page - 1;
    this.getTeams();
  }
  onChangeLimit(val) {
    this.offset = 0;
    this.getTeams();
  }

  private getInspectletUrl(teamAnalyticsId: string) {
    const inspectletParams = {
      paneopen: 'basic',
      tagslist: [
        {
          tag: 'teamId',
          value: teamAnalyticsId,
        },
      ],
      operator: 'and',
    };
    const inspectletUrl = new URL(environment.inspectletBaseUrl);
    inspectletUrl.searchParams.append('tags', JSON.stringify(inspectletParams));
    return inspectletUrl.href;
  }
}
