import { Component, EventEmitter, Output, OnInit, Input, ViewChild } from '@angular/core';
import { UserService } from '../../services/core/user.service';
import { AuthService } from '../../services/core/auth.service';
import * as screenfull from 'screenfull';
import { Screenfull } from 'screenfull';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
})
export class HeaderComponent {
  @Output() toggleSidenav = new EventEmitter<void>();
  @Output() toggleNotificationSidenav = new EventEmitter<void>();
  @ViewChild(MatAutocompleteTrigger) autocomplete: MatAutocompleteTrigger;

  image: string;
  searchQuery: string;
  limit = 10;
  constructor(public userService: UserService, public authService: AuthService) {
    this.image = this.userService.getCurrentUser() ? this.userService.getCurrentUser().photoURL : '';
  }

  fullScreenToggle(): void {
    if ((screenfull as Screenfull).isFullscreen) {
      (screenfull as Screenfull).toggle();
    }
  }
}
