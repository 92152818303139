export const environment = {
  production: false,
  apiUri: 'https://api.qa1.contingenton.com/admin',
  teamEndpoint: 'teams',
  inspectletBaseUrl: 'https://www.inspectlet.com/dashboard/captures/11992816',
  firebase: {
    apiKey: 'AIzaSyAdTNezREUjyl4QeznzCioEegbI1Gxqr1I',
    authDomain: 'qa1-admin-app-c473ce.firebaseapp.com',
    databaseURL: 'https://qa1-admin-app-c473ce.firebaseio.com',
    projectId: 'qa1-admin-app-c473ce',
    storageBucket: 'qa1-admin-app-c473ce.appspot.com',
    messagingSenderId: '618347027952',
    appId: '1:618347027952:web:15983497dadbbad9405135',
    measurementId: 'G-NSYTRJV5Y1',
  },
};
