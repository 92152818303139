import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  selector: 'app-notification-message-snack-bar',
  template: '<span class="{{data.type}}"> {{data.text}} </span>',
  styleUrls: ['./notificationmessagecomponent.scss'],
})
export class NotificationMessageComponent {
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any) {}
}
