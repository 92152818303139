import { Component, OnInit } from '@angular/core';
import { AuthService } from './services/core/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>',
})
export class AppComponent implements OnInit {
  title = 'admin-app';
  constructor(private router: Router, private authService: AuthService) {}
  ngOnInit() {
    if (!this.authService.isAuthenticated()) {
      this.router.navigate(['/session/login']);
    }
  }
}
